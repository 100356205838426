import React from 'react';
import { Link } from 'gatsby';

const Feature = ({href, image, heading, description, to, right, secondary, third, intro}) =>
  <section id="two">
    { right ? 
    null :
    <a href={href} className="image" alt="image" style={intro ? {display:'none'} : null}>
      <img src={image} alt="" data-position="center center" />
    </a> 
    }
    <div className="content" style={intro ? {margin:'0 auto'} : null}>
      <div className="inner">
        <h2>{heading}</h2>
        <p>{description}</p>
        {secondary ? 
            <p> {secondary} </p>  
        : null}
        {third ? 
            <p> {third} </p>  
        : null}
        {to ? 
         <ul className="actions">
          <li>
            <Link className="button" to={to.href}>
              {to.label}
            </Link>
          </li>
        </ul>
        : null }
       
      </div>
    </div>
    { right ? 
          <a href={href} className="image" alt="image">
            <img src={image} alt="" data-position="center center" />
          </a> 
          : null }
  </section>

export default Feature;
