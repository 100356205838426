import React from 'react';

import stanfordLogo from '../../images/stanford_ai_logo.png';
import nyatlasLogo from '../../images/nyatlas_logo.png';
import mychangeLogo from '../../images/mychange_logo.png';
import aodLogo from '../../images/aod_logo.png';

const ClientLogos = () =>
  <div display="inline-flex" style={{textAlign:"center", borderTop:"1px solid gray"}}>
    <a href={"https://ai.stanford.edu/"} target="_blank" className="image" alt="SAIL logo" padding="25px">
      <img src={stanfordLogo} alt="" height="250px" width="400px" style={{padding:"35px"}} data-position="center center" />
    </a> 

    <a href={"https://portal.nyatlas.org/apply"} target="_blank" className="image" alt="nyatlas logo" padding="25px" >
      <img src={nyatlasLogo} alt="" height="250px" width="250px" style={{padding:"35px"}}  data-position="center center" />
    </a> 
    <a href={"https://www.mychange.com/"} target="_blank" className="image" alt="mychange logo" padding="25px">
      <img src={mychangeLogo} alt="" height="125px" width="310px" style={{padding:"35px"}} data-position="center center" />
    </a> 

    <a href={"https://abortionondemand.org/"} target="_blank" className="image" alt="aod logo" padding="25px" >
      <img src={aodLogo} alt="" height="125px" width="300px" style={{padding:"35px"}}  data-position="center center" />
    </a> 
  </div>

export default ClientLogos;
