import React from 'react';

import pic1 from '../../images/team.gif';
import pic2 from '../../images/collab.gif';
import pic3 from '../../images/nancy.jpg';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: '/#',
      image: pic1,
      heading: 'We are BetaStuff',
      intro: "true",
      description: "A lack of funding and access to quality software engineers ruthlessly dampens the impact of non-profits and other organizations committed to social good. We consistently see profits prioritized over people in the world of tech.",
      secondary: 'We want to change that.',
      third: 'We work exclusively with non-profits and companies commited to equity and social change.  We prioritize designing and creating tools to bring our most vulnerable populations from the periphery. No matter your age, location, shape, gender, race, or orientation - we want to help you save the world.',
    },
    {
      href: '/#',
      image: pic2,
      heading: 'Our commitment',
      right: 'true',
      description: 'We provide low-cost software engineering solutions to organizations that are on a mission to save the world ... or at least make the world a slightly more equitable place.',
      secondary: "From non-profits to companies focused on social change- we help them all create and maintain scalable and responsive technical solutions for the problem they're trying to solve.",
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
    {
      href: '/#',
      image: pic3,
      heading: 'Our Team',
      description: 'Nancy earned her B.A. from Swarthmore College and was a social worker and teacher in her past life. She pivoted to tech after feeling disenchanted by her capacity to generate sustainable and scalable change in her field.',
      secondary: 'She is Mexican-American and was the first in her family to attend college. She is passionately curious and committed to giving back to the communities that raised her.',
      to: {
        href: '/generic',
        label: 'Learn more',
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} />)}
    </section>
  );
}

export default Features;
