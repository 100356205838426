import React from 'react';

import ClientLogos from './ClientLogos';

const Clients = () =>
  <section id="three" className="wrapper style1 fade-up" text-align="center" width="100%">
    <div className="inner">
      <h2>Past Clients</h2>
      <p>
        We work with small organizations, and big ones! <br/> Let's make the world a little better together. Regardless of your size.
      <br />
      </p>
      <div className="style1">
        <ClientLogos />
      </div>
    </div>
  </section>

export default Clients;
