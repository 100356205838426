import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'MVP',
      description: "We can help you build your MVP's quickly- getting all the pesky details right, the first time.",
      iconClass: 'fa-cog',
    },
    {
      heading: 'Troubleshoot',
      description: 'We actively listen and help you find a comprehensive and cost-effective solution.',
      iconClass: 'fa-lock',
    },
    {
      heading: 'Develop',
      description: 'We are fluent in Javascript, React, Node, Ruby on Rails, React Native, PostGres, GraphQL, HTML, CSS and a plethora of related tools. We are firm believers in agile development to get you up and running quickly.',
      iconClass: 'fa-code',
    },
    {
      heading: 'Scale',
      description: 'Your tool will grow- abstract to keep your tool relevant for as long as possible, We build all applications with scalability in mind',
      iconClass: 'fa-desktop',
    },
    {
      heading: 'Maintain',
      description: 'Do you already have a tool developed and are hoping to grow, scale or maintain? We can seamlessly integrate into your existing infrastructure.',
      iconClass: 'fa-chain',
    },
    {
      heading: 'Grow',
      description: 'Benefit from our dev experience by working together! We can help you strategically launch new tools -- taking an idea from concept to reality',
      iconClass: 'fa-diamond',
    },
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
