import React from 'react';
import Scroll from '../common/Scroll';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h2>Imagine Differently.</h2>
      <p>
        {/* <a href="http://betastuff.io">BetaStuff</a> */}
        Imagine a world where technology primarily operates for the common good. 
        <br />
        Now imagine that human-centered technology everywhere.
        <br />
        <br />
        This can be our reality. We are here to help.
        {/* and released for free under the{' '}
        <a href="http://html5up.net/license">Creative Commons</a>. */}
        <br /> 
        {/* We provide low-cost software engineering solutions to companies on a mission for humanity.  */}
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              Who are we?
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
